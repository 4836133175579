<template>
  <div class="container mx-auto my-4 space-y-4">
    <div class="flex items-center">
      <input v-model="date" type="date" class="w-24 border-2 border-red-500" />
      <new-button @click="getMetrics" :disabled="isFetching">{{
        isFetching ? 'Loading' : 'Search'
      }}</new-button>
    </div>

    <div v-if="metrics">
      <p>Total Requests: {{ metrics.total }}</p>
      <p>Successful Requests: {{ metrics.successfulDMsCount }}</p>
      <p>Failed Requests: {{ metrics.failedDMsCount }}</p>
      <p>Too many requests errors: {{ metrics.tooManyRequestsCount }}</p>
      <p>Number of fetched replies for standard users: {{ metrics.standardFetchedRepliesCount }}</p>
      <p>Number of fetched replies for premium users: {{ metrics.premiumFetchedRepliesCount }}</p>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import controller from '../controller';

  export default {
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser' }),
    },
    data() {
      return {
        date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        metrics: null,
        isFetching: false,
      };
    },
    methods: {
      async getMetrics() {
        try {
          this.isFetching = true;
          const response = await controller.autoDM.getMetrics(
            this.currentUser,
            moment(this.date).format('YYYY-MM-DD')
          );
          this.metrics = response.data;
        } finally {
          this.isFetching = false;
        }
      },
    },
  };
</script>
